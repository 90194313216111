<div [class.noWrap]="noWrap">
  <div *ngIf="!isLink" class="display-string">{{value}}</div>

  <div *ngIf="isLink" class="link-description" >
    <a *ngIf="linkTitle$ | async as linkTitle; else loadingLink" [href]="value" target="_blank" [pTooltip]="linkTitle" [escape]="false" tooltipStyleClass="link-tooltip" tooltipPosition="top"
      showDelay="250" [innerHtml]="linkTitle"></a>
    <ng-template #loadingLink>
      loading...
    </ng-template>
  </div>
</div>