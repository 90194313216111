<p-sidebar [(visible)]="isNotesPanelVisible" [baseZIndex]="10000" blockScroll="true">
  <div style="display: flex; align-items: center;">
    <img style="height: 60px; margin-top: -17px;" src="./assets/images/ducky_note_logo.png">
    <h1 style="font-weight: lighter; margin-left: 5px;">Notes</h1>
  </div>
  <dd-notes></dd-notes>
</p-sidebar>

<p-sidebar [(visible)]="isHistoryPanelVisible" blockScroll="true" position="right">
  <div style="display: flex; align-items: center;">
    <h1 style="margin-top: 5px; margin-bottom: 10px; font-weight: lighter;">Previous Dinners</h1>
  </div>
  <dd-meal-history [shouldLoad]="isHistoryPanelVisible"></dd-meal-history>
</p-sidebar>

<div class="ui-g ui-fluid">
  <div class="ui-g-12">
    <div style="display: flex; justify-content:center;">
      <button pButton type="button" (click)="showNotes()" icon="pi pi-pencil" style="margin-right: auto"></button>
      <div class="week-picker" style="display: flex">
        <i class="material-icons clickable-icon" (click)="previousWeek()">arrow_backward</i>
        <div class="week-picker-calendar">
          <span>Choose Date:</span>
          <p-calendar [(ngModel)]="weekPickerDate" (ngModelChange)="onWeekPickerDateChange()" [showIcon]="true" dateFormat="mm/dd/yy" [style]="{'width': '160px'}"></p-calendar>
        </div>
        <i class="material-icons clickable-icon" (click)="nextWeek()">arrow_forward</i>
      </div>
      <button pButton type="button" (click)="showHistory()" icon="pi pi-list" style="margin-left: auto"></button>
    </div>
    <div class="week-date-range-display">
      <strong>{{mondayDisplayString}}</strong> to
      <strong>{{sundayDisplayString}}</strong>
    </div>
    <div class="week-display">
      <div *ngFor="let dayOfWeek of week" class="week-day">
        <dd-week-day [date]="dayOfWeek" (swap)="swapDay(dayOfWeek, $event)"></dd-week-day>
      </div>
    </div>
  </div>
</div>

<dd-date-swap-dialog></dd-date-swap-dialog>