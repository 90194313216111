import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, startWith, shareReplay, catchError, tap } from "rxjs/operators";
import { MetaService } from '../../../core/meta/meta.service';


@Component({
  selector: 'dd-food-display',
  templateUrl: './food-display.component.html',
  styleUrls: ['./food-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoodDisplayComponent implements OnInit, OnChanges {
  @Input() value: string;
  @Input() linkTitle: string;
  @Output() viewChanged: EventEmitter<void> = new EventEmitter<void>();
  public isLink: boolean = false;
  public noWrap: boolean = false;

  public linkTitle$: Observable<string>;

  constructor(private metaService: MetaService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.checkForLink();
    }
  }

  private checkForLink() {
    this.isLink = this.value && (this.value.startsWith('http://') || this.value.startsWith('https://'));;
    if (this.isLink) {
      this.refreshLinkTitle();
    }
  }

  private refreshLinkTitle() {
    this.linkTitle$ = this.isLink
      ? this.metaService.getMeta(this.value).pipe(
        map(meta => { return meta.title || this.value }),
        catchError(e => {
          return of(this.value)
        }),
        tap(() => setTimeout(() => this.viewChanged.next())),
        shareReplay(1),
      )
      : of(this.value);
  }
}
