import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { DuckyNoteModule } from '../core/ducky-note/ducky-note.module';

import { NotesPageComponent } from './notes-page/notes-page.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DuckyNoteModule,
  ],
  declarations: [NotesPageComponent],
  exports: [],
  providers: []
})
export class NotesModule {
}
