import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './core/layout/layout.module';

import { DuckyNoteModule } from './core/ducky-note/ducky-note.module';
import { DinnersModule } from './dinners/dinners.module';
import { MetaModule } from './core/meta/meta.module';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { NotesModule } from './notes/notes.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ToastModule,
    LayoutModule,
    DuckyNoteModule.forRoot(),
    MetaModule.forRoot(),
    DinnersModule,
    NotesModule,
  ],
  providers: [MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }

