<form [formGroup]="mealForm" novalidate>
  <div class="ui-g" *ngIf="isLoaded; else loading">
    <div class="ui-g-12 ui-g-nopad" style="position: relative;">
      <span class="type-header">Dinner</span>
      <dd-food-input formControlName="dinner" (keydown.enter)="goToFirstSide()"></dd-food-input>
    </div>
    <div class="ui-g-12 ui-g-nopad" style="margin-top: 10px" formArrayName="sides">
      <span class="type-header">Sides</span>
      <ng-container *ngIf="mealForm.controls.sides.controls.length; else noSides">
        <div *ngFor="let side of mealForm.controls.sides.controls; let i = index;" [formGroupName]="i" style="display: flex; margin-bottom: 5px; position: relative;">
          <dd-food-input #side formControlName="side" (keydown.enter)="addSide()" style="flex: 1"></dd-food-input>
          <i class="material-icons clickable-icon" (click)="removeSide(i)">close</i>
        </div>
      </ng-container>
      <ng-template #noSides>
        <div style="text-align: center; font-size: .9em;">No Sides</div>
      </ng-template>
    </div>
    <div class="ui-g-12 ui-g-nopad" style="margin-top: 15px">
      <button type="button" pButton label="Add Side" icon="pi pi-plus" (click)="addSide()" tabindex="-1"></button>
    </div>
  </div>
</form>

<ng-template #loading>
  <dd-spinner></dd-spinner>
</ng-template>