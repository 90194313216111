
import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { IPageInfo } from 'ngx-virtual-scroller';

import { DinnerService } from '../dinner.service';
import { DuckyMeal } from '../../core/ducky-note/ducky-meal.model';
import { DuckyMealService } from '../../core/ducky-note/ducky-meal.service';
import { DateSwapDialogService } from '../date-swap-dialog/date-swap-dialog.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dd-meal-history',
  templateUrl: './meal-history.component.html',
  styleUrls: ['./meal-history.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MealHistoryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() shouldLoad: boolean = false;
  public loaded: boolean = false;
  public copyToDaysMenuItems: MenuItem[] = [];
  public mealRowClickedForMenu: DuckyMeal;
  public mealBuffer: DuckyMeal[] = null;
  public isLoadingHistory: boolean = true;
  public lastDateLoaded: Date;
  public dinnerFilter: string = null;
  public dinnerFilter$: Subject<string> = new Subject<string>();

  constructor(
    private messageService: MessageService,
    private dinnerService: DinnerService,
    private duckyMealService: DuckyMealService,
    private dateSwapDialogService: DateSwapDialogService
  ) {
    this.dinnerService.$startingDate.subscribe(startingDate => this.refreshMenuItems(startingDate));
  }

  ngOnInit() {
    this.dinnerFilter$
      .pipe(debounceTime(650), distinctUntilChanged())
      .subscribe(model => { this.loadMeals(); });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.shouldLoad.currentValue && !this.loaded) {
      this.loadMeals();
    }
  }

  ngOnDestroy() { 
    this.dinnerFilter$?.complete();
  }

  private refreshMenuItems(startingDate: Date) {
    const momentDate = moment(startingDate);

    const copyToDaysMenuItems = [];
    for (let i = 1; i <= 7; i++) {
      const day = momentDate.clone().isoWeekday(i);
      copyToDaysMenuItems.push({
        label: day.format('dddd - MMM Do'),
        command: () => this.copyToDate(day.toDate()),
      });
    }
    copyToDaysMenuItems.push({ label: 'Choose Date', command: () => this.openCopyToDialog() });
    this.copyToDaysMenuItems = copyToDaysMenuItems;
  }

  public onDinnerFilter(value) {
    this.dinnerFilter$.next(value);
  }

  public clearFilter() {
    this.dinnerFilter = null;
    this.dinnerFilter$.next(null);
  }

  public openCopyToDialog() {
    if (this.mealRowClickedForMenu) {
      this.dateSwapDialogService.swap(new Date(this.mealRowClickedForMenu.date));
    }
  }

  public copyToDate(targetDate: Date) {
    if (this.mealRowClickedForMenu) {
      // Derpy clone.
      const newMeal = <DuckyMeal>JSON.parse(JSON.stringify(this.mealRowClickedForMenu));

      // Set the new date
      newMeal.date = targetDate;

      // Clear any old ids.
      for (let i = 0; i < newMeal.sides.length; i++) {
        newMeal.sides[i].id = null;
      }

      // Save the new copy.
      this.duckyMealService.saveMeal(newMeal).subscribe(() => {
        this.dateSwapDialogService.refresh(newMeal.date);
      });
    }
  }

  public getSidesTooltip(meal: DuckyMeal) {
    if (!meal.sides.length) {
      return null;
    }
    return meal.sides.map(s => '- ' + s.side).join('<br>');
  }

  public fetchMore(event: IPageInfo) {
    if (this.isLoadingHistory || event.endIndex !== this.mealBuffer.length - 1) return;
    const lastDateLoaded = moment(this.lastDateLoaded);
    if (lastDateLoaded.year() < 2015)
      return;

    const twoMonthsFromLast = lastDateLoaded.clone().subtract('2', 'months').toDate();
    this.isLoadingHistory = true;
    this.duckyMealService.search(twoMonthsFromLast, lastDateLoaded.clone().subtract('1', 'day').toDate(), this.dinnerFilter).subscribe(result => {
      this.lastDateLoaded = twoMonthsFromLast;
      this.mealBuffer = this.mealBuffer.concat(result);
      this.isLoadingHistory = false;
    });
  }

  public mealTrackByFn(index: number, meal: DuckyMeal) {
    return meal.date;
  }

  private loadMeals() {
    this.loaded = true;
    const oneMonthAgo = moment().subtract('1', 'month').toDate();
    this.lastDateLoaded = oneMonthAgo;
    this.duckyMealService.search(oneMonthAgo, moment().toDate(), this.dinnerFilter).subscribe(result => {
      this.mealBuffer = result;
      this.isLoadingHistory = false;
    });
  }
}
