<div class="ui-g ui-fluid" style="width: 100%;">
  <div class="ui-g-12">
    <div style="display: flex; flex-direction:row">
      <input type="text" pInputText placeholder="filter..." style="flex: 1" [(ngModel)]="dinnerFilter" (ngModelChange)="onDinnerFilter($event)">
      <i class="material-icons clickable-icon" (click)="clearFilter()">close</i>
    </div>
  </div>
  <div class="ui-g-12 layout-wrapper layout-compact" style="padding: 0;">
    <div *ngIf="mealBuffer; else loading">
      <virtual-scroller #scroll [enableUnequalChildrenSizes]="true" [items]="mealBuffer" class="meal-viewport" (vsEnd)="fetchMore($event)">
        <div class="meal-history-item" *ngFor="let meal of scroll.viewPortItems; trackBy: mealTrackByFn">
          <i class="material-icons clickable-icon" (click)="mealRowClickedForMenu = meal; menu.toggle($event)" style="margin-right: 10px;">call_made</i>
          <div style="flex: 1;">
            <div class="meal-date">{{meal.date | date: 'MMMM d (EEEE), y'}}</div>
            <dd-food-display [value]="meal.dinner" (viewChanged)="scroll.invalidateCachedMeasurementForItem(meal)"></dd-food-display>
            <div class="meal-sides">
              <div style="position: absolute; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%;">
                <span>Sides: </span>
                <span *ngIf="!meal?.sides.length" style="color: #6d6d6d;">
                  none
                </span>
                <span *ngFor=" let side of meal.sides; last as isLast">
                  {{side.side}}{{isLast ? '' : ', '}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isLoadingHistory" class="text-center" style="padding: 10px 0;">Loading more...</div>
      </virtual-scroller>
    </div>
    <ng-template #loading>
      <div class="text-center">
        <dd-spinner></dd-spinner>
        <h3>Loading meals</h3>
      </div>
    </ng-template>
  </div>
</div>

<p-menu #menu popup="popup" [model]="copyToDaysMenuItems" appendTo="body" [style]="{'width':'250px'}"></p-menu>