import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';

import { Meta } from './meta.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class MetaService {
    private readonly baseUrl = environment.baseApiUrl + 'Meta';

    private metaCache: { [key: string]: Meta } = {};

    constructor(private http: HttpClient) { }

    public getMeta(url: string): Observable<Meta> {
        var cachedValue = this.metaCache[url]
        if (this.metaCache[url])
            return of(cachedValue);

        return <Observable<Meta>>this.http.get(this.baseUrl + '?url=' + url).pipe(tap(meta => {
            this.metaCache[url] = meta;
        }));
    }
}
